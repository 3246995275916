import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import greatIntrigueBanner from '../images/great-intrigue-banner.png';
import lavaLlamaNycScreenshot from '../images/lava-llama-nyc-screenshot.png';
import lavaLlamaWebsiteScreenshot from '../images/lava-llama-website-screenshot.png';
import greatIntrigueLogo from '../images/great-intrigue-logo.png'
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Header from '../components/Header';

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Home - Great Intrigue</title>
      </Helmet>
      <Header anchorLinks />
      <main id="main-content">

        <h1 className="sr-only">Great Intrigue</h1>

        <div id="home-hero" className="position-relative overflow-hidden text-center bg-light mb-3 mb-md-5">
          <img alt="Great Intrigue Logo Banner" src={greatIntrigueBanner} className="w-100" />
        </div>

        <div className="container">

          {/* START THE FEATURETTES */}

          <hr id="games" className="featurette-divider invisible my-5" />

          <div className="row featurette py-3">
            <div className="col-lg-7">
              <h2 className="featurette-heading">Games</h2>
              <p className="lead">...or more accurately, "game"! We have one game out right now, and that game is Lava Llama! Guide your llama to safety in this 8-bit runner game, jumping from platform to platform and avoiding various obstacles as you go. Check out <a href="https://www.lavallama.com/">www.lavallama.com</a> for more info!</p>
            </div>
            <div className="col-lg-5">
              <img className="featurette-image img-fluid mx-auto img-thumbnail" src={lavaLlamaNycScreenshot} alt="Lava Llama game screenshot" />
            </div>
          </div>

          <hr id="news" className="featurette-divider my-5" />

          <div className="row featurette py-3">
            <div className="col-lg-7">
              <h2 className="featurette-heading">News</h2>
              <p className="lead">
                Lava llama is available for iOS and Android! Check it out!
                <br />
                <a href="https://www.lavallama.com/">www.lavallama.com</a>
              </p>
              <p>
                <a className="btn btn-primary mb-3 me-3" href="https://itunes.apple.com/us/app/lava-llama/id1131968402">Download for iOS &raquo;</a>
                <a className="btn btn-primary mb-3" href="https://play.google.com/store/apps/details?id=com.wednesdaynightgames.lavallama">Download for Android &raquo;</a>
              </p>
            </div>
            <div className="col-lg-5">
              <img className="featurette-image img-fluid mx-auto img-thumbnail" src={lavaLlamaWebsiteScreenshot} alt="Lava llama website screenshot" />
            </div>
          </div>

          <hr id="about" className="featurette-divider my-5" />

          <div className="row featurette py-3">
            <div className="col-lg-7">
              <h2 className="featurette-heading">About</h2>
              <p className="lead">Lava Llama was made by a team of friends who have a passion for developing games. They have been involved in a variety of game development projects including Lava Llama (available now for iOS and Android), The Adventures of Dude Man, and Project Shadow. Will they make more games in the future? Maybe! For now though, all effort is being channelled into improving and promoting Lava Llama.</p>
            </div>
            <div className="col-lg-5">
              <img className="featurette-image img-fluid mx-auto img-thumbnail" src={greatIntrigueLogo} alt="Great Intrigue logo" />
            </div>
          </div>

          <hr id="contact" className="featurette-divider my-5" />

          <div className="row featurette py-3">
            <div className="col-lg-7">
              <h2 className="featurette-heading">Contact</h2>
              <p className="lead">Feel free to send us questions and feedback!</p>
            </div>
            <div className="col-lg-5">
              <ContactForm source="www.greatintrigue.com" />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </Layout >
  )
};

export default IndexPage;
