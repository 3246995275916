import React, { useState } from "react";
import HCaptcha from '@hcaptcha/react-hcaptcha';

export default function ContactForm({ source }) {
  const [errors, setErrors] = useState([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [latestCaptchaResponse, setLatestCaptchaResponse] = useState();
  const [submissionInProgress, setSubmissionInProgress] = useState(false);
  const [submissionComplete, setSubmissionComplete] = useState(false);

  const setupCaptchaAccessibilityLabels = () => {
    ['g-recaptcha-response', 'h-captcha-response'].forEach((captchaFieldName) => {
      const textarea = document.querySelector(`textarea[name="${captchaFieldName}"]`);
      if (textarea) {
        const label = document.createElement('label');
        label.htmlFor = textarea.id;
        label.innerText = captchaFieldName;
        label.style.display = 'none';
        textarea.parentNode.insertBefore(label, textarea);
      }
    })
  };

  const validateFields = () => {
    const validationErrors = [];

    if (name === '') {
      validationErrors.push('Name is required.');
    }

    if (email === '') {
      validationErrors.push('Email address is required.');
    } else if (email.match(/^(.+)@[a-z0-9.]+\.[a-z0-9]+$/) === null || email.length > 100) {
      validationErrors.push('Invalid email address.');
    }

    if (message === '') {
      validationErrors.push('Message is required.');
    }

    if (!latestCaptchaResponse) {
      validationErrors.push('Captcha is required.');
    }

    return validationErrors;
  };

  const submitContactForm = (e, captchaResponse) => {
    e.preventDefault();

    const validationErrors = validateFields();

    if (validationErrors.length > 0) {
      setErrors(validationErrors);
      return;
    }

    setErrors([]);
    setSubmissionInProgress(true);

    fetch('https://services.ejdigitalmedia.com/contact', {
      method: 'POST',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ source, name, email, message, 'g-recaptcha-response': latestCaptchaResponse }),
    }).then(response => response.json()).then((data) => {
      setSubmissionInProgress(false);
      if (data.success) {
        setSubmissionComplete(true);
      } else {
        setErrors(data.errors);
      }
    });
  };

  return (
    <form id="contact-form">
      <div className="p-3 border bg-light">
        <div className="mb-3">
          <label htmlFor="contact-name" className="form-label">Name</label>
          <input type="text" onChange={(e) => setName(e.target.value)} className="form-control" id="contact-name" aria-describedby="contact-form" placeholder="Enter name" />
        </div>
        <div className="mb-3">
          <label htmlFor="contact-email" className="form-label">Email address</label>
          <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="contact-email" aria-describedby="contact-form" placeholder="Enter email" />
        </div>
        <div className="mb-3">
          <label htmlFor="contact-message" className="form-label">Message</label>
          <textarea onChange={(e) => setMessage(e.target.value)} className="form-control" id="contact-message" rows="3"></textarea>
        </div>
        {
          errors.length > 0 && (
            <div id="contact-error-message" className="alert alert-danger mt-2 mb-3">
              <p>
                Please correct the following errors and submit the form again:
              </p>
              <ul className="mb-0">
                {errors.map((error) => <li key={error}>{error}</li>)}
              </ul>
            </div>
          )
        }
        {
          submissionComplete ? (
            <div id="contact-thank-you-for-message" className="mt-3">
              Thank you for your message!
            </div>
          ) : (
            <>
              <HCaptcha
                sitekey="405bf2cb-db4a-4aad-8821-4576f8b365b6"
                onVerify={(token, _ekey) => { setLatestCaptchaResponse(token); setErrors([]); }}
                onLoad={setupCaptchaAccessibilityLabels}
              />
              <button onClick={submitContactForm} type="submit" className="btn btn-primary float-end" disabled={submissionInProgress}>
                {submissionInProgress ? 'Submitting...' : 'Submit'}
              </button>
            </>
          )
        }
        <div className="clearfix"></div>
      </div>

    </form >
  );
}
